<template>
  <div class="question-list" >
    <div class="question-titile">
      <div style="flex: 1">时间</div>
    </div>
    <div @click="activeIndex = index" class="question-item" v-for="(question, index) in list" :key="question._id">
      <div class="question-head">
        <div style="flex: 1">
          <span>[{{question.replied ? '已回复' : '未回复'}}] {{getDate(question.createdAt)}}</span>
          <img v-if="activeIndex === index" class="icon-bottom" src="../../../assets/icon_bottom.png" alt="">
          <img v-else class="icon-right" src="../../../assets/icon_right.png" alt="">
        </div>
      </div>
      <div class="question-table" v-if="activeIndex === index">
        <table border="1">
          <tbody>
            <tr>
              <td>问题</td>
              <td style="text-align: left">{{question.question}}</td>
            </tr>
            <tr>
              <td>回复</td>
              <td style="text-align: left">{{question.answer}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <mt-button class="question-btn" @click="$router.push('/hospital_doctor')" type="primary">返回</mt-button>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'QuestionsList',
  data() {
    return {
      activeIndex: null,
      list: []
    }
  },
  components: {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
    this.getHospitalQuestions()
  },
  methods: {
    getDate (createdAt) {
      return moment(createdAt).format('YYYY-MM-DD HH:mm')
    },
    getHospitalQuestions () {
      this.$store.httpDispatch({})('getHospitalQuestions')
      .then((res) => {
        if (res.data.code === 0) {
          this.list = res.data.data.questions
        }
      })
    }
  }
}
</script>

<style lang="less">
.question-list {
  padding-top: 50px;
  padding: 50px 20px 0;
  box-sizing: border-box;
  .question-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    left: 50%;
    margin-left: -40vw;
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
  }
  .question-titile {
    background: none;
    width: 100%;
    display: flex;
    height: 35px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 10px;
    font-size: 15px;
    div {
      background: #007EC5;
      border-radius: 5px;
    }
  }
  .question-item {
    margin-bottom: 10px;
    .question-table {
      background: #B2B2B2;
      border-radius: 8px;
      margin-top: 10px;
      table {
        width: 100%;
        border-radius: 10px;
        border-collapse: collapse;
        font-family: "黑体";
        font-weight: 800;
        tr {
          height: 30px;
          td:first-child {
            width: 80px;
          }
          td:nth-child(1) {
            width: 80px;
          }
        }
      }
      table, th, td { border: 1px solid #D0D0D0;
      word-break: break-all;
      }
    }
  }
  .question-head {
    position: relative;
    width: 100%;
    background: #fff;
    display: flex;
    height: 35px;
    font-size: 12px;
    line-height: 35px;
    border-radius: 5px;
    font-family: "黑体";
    font-weight: 800;
    .headerDivider {
      box-sizing: border-box;
      border-left:1px solid #ADD6E9;
      border-right:1px solid #ADD6E9;
      height: 18px;
      position:absolute;
      left: 60px;
      top: 8.5px;
    }
    .icon-right {
      height: 18px;
      position: absolute;
      right: 10px;
      top: 8.5px;
    }
    .icon-bottom {
      width: 18px;
      position: absolute;
      right: 10px;
      top: 15px;
    }
  }
}
</style>
