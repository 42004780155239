<template>
  <div class="user-info-page">
    <mt-field class="required" label="姓名" v-model="form.nickname"></mt-field>
    <mt-field class="required" label="城市" type="text" v-model="form.city"></mt-field>
    <a data-v-426df720="" class="mint-cell mint-field"><!---->
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title"><!---->
          <span class="mint-cell-text">所属医院</span> <!---->
        </div>
        <div class="mint-cell-value">
          <select v-model="form.hospitalId">
            <option value=""></option>
            <option :value="hospital._id" v-for="hospital in hospitals" :key="hospital._id">{{hospital.name}}</option>
          </select>
        </div>
      </div>
      <div class="mint-cell-right"></div>
    </a>
    <mt-field class="required" label="联系人手机" placeholder="" type="tel" v-model="form.mobile"></mt-field>
    <mt-field label="邮箱" type="email" v-model="form.email"></mt-field>
    <mt-button class="user-btn" @click="submit" type="primary">提交</mt-button>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import { Toast } from 'mint-ui'
export default {
  name: 'user-info-page',
  data() {
    return {
      hospitals: [],
      form: {
        nickname: '',
        hospitalId: '',
        mobile: '',
        email: '',
        city: ''
      },
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
    this.form = Object.assign({}, this.form, this.user)
    this.form.sex = this.form.sex !== 1 ? 'female' : 'male'
    console.log(this.form)
    this.getHospital()
  },
  methods: {
    getHospital () {
      this.$store.httpDispatch({})('getHospitalsName').then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          this.hospitals = data.hospitals
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    submit () {
      const body = Object.assign({}, this.form)
      if (!this.form.mobile) {
        return Toast({
          message: '请填写手机号',
          duration: 3000
        })
      }
      if (!this.form.nickname) {
        return Toast({
          message: '请填写用户名',
          duration: 3000
        })
      }
      if (!this.form.hospitalId) {
        return Toast({
          message: '请选择所属医院',
          duration: 3000
        })
      }
      if (!this.form.city) {
        return Toast({
          message: '请填写城市',
          duration: 3000
        })
      }
      this.$store.httpDispatch({})('updateDoctorInfo', body).then((res) => {
        const { code } = res.data
        if (code === 0) {
          this.$store.httpDispatch({})('getUserInfo').then((res) => {
            console.log(res)
            let { code, data } = res.data
            if (code === 0) {
              this.$store.dispatch('setUser', { user: data.user })
              this.$router.push('/doctor')
              return Toast({
                message: '修改成功',
                duration: 3000
              })
            }
          }).catch((err) => {
            console.log(err)
          })
        }
      }).catch((err) => {
        console.log(err.data)
        return Toast({
          message: err.data.msg,
          duration: 3000
        })
      })
    }
  }
}
</script>
<style lang="less">
.user-info-page {
  box-sizing: border-box;
  padding: 0 10px;
  padding-top: 50px;
  select {
    background: #fff!important;
  }
  input {
    background: #fff!important;
  }
  .user-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    border-radius: 100px;
    margin-top: 30px;
  }
  .required {
    .mint-cell-title::after {
      position: absolute;
      content: '* ';
      left: 100px;
      top: 5px;
      color: red;
    }
  }
  .city-picker {
    .mint-cell-value {
      div {
        font: 400 11px system-ui;
        font-size: inherit;
        width: 80px;
        border: 0;
      }
    }
  }
  .mint-cell {
    background: none;
    margin-bottom: 5px;
    .mint-cell-wrapper {
      border: 0;
      background: none;
    }
    .mint-cell-title {
      .mint-cell-text {
        display: inline-block;
        width: 100%;
        text-align: justify;
        vertical-align: middle;
        padding-top: 17px;
      }
      span:after{
        content:'';
        width: 100%;
        display: inline-block;
        height: 0;
      }
      width: 90px;
      margin-right: 10px;
    }
    .mint-cell-value {
      border-radius: 5px;
      background: #fff;
      height: 45px;
      padding-left: 5px;
      select {
        width: 100%;
        font-size: 16px;
        border: 0;
        background: #ffff;
      }
    }
  }
  .mint-popup-bottom {
    width: 100vw;
  }
  .user-gender {
  }
  .male {
    display: inline-block;
    margin: 0 20px 0 10px;
    input[type="radio"] + label::before {
      border: 1px solid #017ECB;
    }
    input[type="radio"]:checked + label::before {
      background-color: #017ECB;
    }
  }
  .female {
    display: inline-block;
    input[type="radio"] + label::before {
      border: 1px solid #E293B3;
    }
    input[type="radio"]:checked + label::before {
      background-color: #E293B3;
    }
  }
  input[type="radio"] + label::before {
    content: "\a0"; /*不换行空格*/
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    width: 1em;
    height: 1em;
    margin-right: .4em;
    border-radius: 50%;
    text-indent: .15em;
    line-height: 1;
  }
  input[type="radio"]:checked + label::before {
    width: 0.6em;
    height: 0.6em;
    background-clip: content-box;
    padding: .2em;
  }
  input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
}
</style>
