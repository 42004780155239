<template>
  <div class="user-list" >
    <div class="count-info">
      <div>
        <p>
          <img style="width: 50px;height;auto" :src="user.headimgurl" alt="">
          <span style="vertical-align: top;position:relative;top:15px">
          尊敬的{{user.nickname}}医生
          </span>
         <mt-button class="question-btn" @click="show = true" type="primary">在线咨询</mt-button>
        </p>
      </div>
    </div>
    <div class="time-picker-box">
      <div class="timepicker">
        <div @click="openPicker('startDate')" class="start flex-1">
          {{ startDate ? getDate(startDate) : '开始时间' }}
        </div>
        <div>
          至
        </div>
        <div @click="openPicker('endDate')" class="end flex-1">
          {{ endDate ? getDate(endDate) : '结束时间' }}
        </div>
      </div>
    </div>
    <div class="input-search">
      <mt-field class="nickname-input" placeholder="姓名" v-model="nickname"></mt-field>
      <mt-button class="check-btn" @click="getUsersByDoctorId" type="primary">查询</mt-button>
    </div>
    <mt-datetime-picker
      ref="startDate"
      :startDate="new Date('2019-01-01')"
      type="date"
      @confirm="handleConfirm('startDate')"
      v-model="startDate">
    </mt-datetime-picker>
    <mt-datetime-picker
      :startDate="new Date('2019-01-01')"
      @confirm="handleConfirm('endDate')"
      ref="endDate"
      type="date"
      v-model="endDate">
    </mt-datetime-picker>
    <div class="count-info">
      <div>
        <span style="margin-right: 20%">
        用户总数：{{total}}
        </span>
        <span>
        退组人数：{{locked}}
        </span>
      </div>
      <div>
        <span style="margin-right: 20%">
          试用人数：{{typeCount.onemonth}}
        </span>
        <span>
          入组人数：{{typeCount.oneyear + typeCount.twoyear + typeCount.threeyear + typeCount.fiveyear}}
        </span>
      </div>
      <div>
      </div>
    </div>
    <div class="user-titile">
      <div class="title-item">姓名<div class="headerDivider"></div></div>
      <div class="title-item">入组时间<div class="headerDivider"></div></div>
      <!-- <div class="title-item">套餐<div class="headerDivider"></div></div> -->
      <div class="title-item">是否退组</div>
    </div>
    <div @click="$router.push({
      path: `/hospital_doctor/user/${user.openid}`
    })" class="user-item" v-for="(user) in list" :key="user._id">
      <div class="user-head">
        <div class="title-item">{{ user.nickname }}<div class="headerDivider"></div></div>
        <div class="title-item">{{ getDate(user.startDate) }}<div class="headerDivider"></div></div>
        <!-- <div class="title-item">{{ packageMap[user.package] }}<div class="headerDivider"></div></div> -->
        <div :class=" user.locked ? 'danger title-item' : 'title-item' ">{{ user.locked ? '是' : '否' }}</div>
      </div>
    </div>
    <div v-if="show" class="qa-mask">
      <div class="qa-dialog">
        <div class="qa-dialog-content">
          <div class="qa-dialog-p">
            <div style="margin-bottom: 10px">{{user.hospital && user.hospital.name}}--{{user.nickname}}</div>
            <div class="qa-input-box">
              <div class="qa-label" style="width: 60px;text-align: left">问题：</div>
              <div>
                <textarea rows="5" cols="20" v-model="form.question" />
              </div>
            </div>
            <div class="qa-input-box">
              <div class="qa-label" style="width: 60px;text-align: left">联系方式：</div>
              <div>
                <input maxlength="20" type="text" v-model="form.mobile">
              </div>
            </div>
          </div>
        </div>
        <div class="qa-dialog-footer">
          <mt-button class="qa-btn qa-submit" style="background: #999" @click="show = false">关闭</mt-button>
          <mt-button class="qa-btn qa-submit" @click="submit">提交</mt-button>
        </div>
        <div class="qa-dialog-footer">
          <mt-button class="qa-btn qa-submit" @click="$router.push('/hospital_doctor/questions')">问题列表</mt-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Toast } from 'mint-ui'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  name: 'UsersList',
  data() {
    return {
      show: false,
      form: {
        question: '',
        mobile: ''
      },
      startDate: '',
      nickname: '',
      endDate: '',
      list: [],
      total: 0,
      locked: 0,
      packageMap: {
        onemonth: '一个月',
        threemonth: '三个月',
        oneyear: '一年',
        halfyear: '半年',
        twoyear: '两年',
        threeyear: '三年',
        fiveyear: '五年',
        other: '其他'
      },
      typeCount: {}
    }
  },
  components: {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
    this.getUsersByDoctorId()
  },
  methods: {
    handleConfirm (key) {
      return () => {
        this[key] = value
      }
    },
    submit () {
      const body = Object.assign({}, this.form)
      if (!this.form.question) {
        return Toast({
          message: '问题',
          duration: 3000
        })
      }
      if (!this.form.mobile) {
        return Toast({
          message: '请填写联系方式',
          duration: 3000
        })
      }
      this.$store.httpDispatch({})('addDoctorQuestion', body).then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          this.show = false
          this.form.mobile = ''
          this.form.question = ''
          Toast({
            message: '提交成功',
            duration: 3000
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    getDate (createdAt) {
      return moment(createdAt).format('YY-MM-DD')
    },
    openPicker(key) {
      this.$refs[key].open()
    },
    getUsersByDoctorId () {
      if (
        (this.startDate && !this.endDate) ||
        (!this.startDate && this.endDate)
      ) {
        return Toast({
          message: '请选择开始时间与结束时间',
          duration: 5000
        })
      }
      this.$store.httpDispatch({})('addLog', {
        action: '查看',
        type: '用户列表',
        from: 'client',
        content: '查看用户列表'
      }).catch((err) => {
        console.log(err)
      })
      this.$store.httpDispatch({
      })('getUsersByDoctorId', {
        startDate: this.startDate ? new Date(this.startDate).toISOString() : '',
        endDate: this.endDate ? new Date(this.endDate).toISOString() : '',
        nickname: this.nickname
      })
      .then((res) => {
        console.log(res.data)
        if (res.data.code === 0) {
          this.list = res.data.data.users
          this.total = res.data.data.total
          this.locked = res.data.data.locked
          this.typeCount = res.data.data.typeCount
        }
      })
    }
  }
}
</script>

<style lang="less">
.user-list {
  padding-top: 50px;
  padding: 20px 20px 0;
  box-sizing: border-box;

  .qa-mask {
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .qa-dialog {
    .qa-dialog-content {
      text-align: left;
      background: #2271C1;
      color: #fff;
      width: 90vw;
      margin: 150px auto 30px;
      padding: 20px 30px 30px;
      box-sizing: border-box;
      border-radius: 5px;
    }
    .qa-input-box {
      // display: flex;
      margin-bottom: 20px;
      .qa-label {
        font-size: 12px;
      }
      div {
        // display: inline-block;
        input, textarea {
          padding: 5px 10px;
          box-sizing: border-box;
          width: 100%;
          border: 0;
          border-radius: 5px;
        }
      }
    }
    .mint-cell-title {
      color: #333;
    }
    .qa-btn {
      background: #fff;
      border:1px solid #fff;
      padding: 0px 30px;
      margin-right: 50px;
    }
    .qa-btn:last-child {
      margin-right: 0;
    }
    .qa-submit {
      background: #2DB04D;
      color : #fff;
    }

    .qa-dialog-footer {
      margin-bottom: 20px;
    }
  }

  .time-picker-box {
    display: flex;
  }
  .danger {
    color: red
  }
  .check-btn {
    height: 40px;
    line-height: 40px;
    background: #0094CE;
    padding: 0 20px;
  }
  .question-btn {
    height: 35px;
    line-height: 35px;
    background: #2DB04D;
    padding: 0 15px;
    float: right;
  }
  .nickname-input {
    background: none;
    border: none;
    height: 40px;
  }
  .nickname-input .mint-cell-wrapper {
    background: #fff;
    border-radius: 5px;
    height: 40px;
    min-height: 40px;
  }
  .timepicker {
    flex: 1;
    background: #eee;
    height: 40px;
    line-height: 40px;
    display: flex;
    border: 1px solid #0094CE;
    border-radius: 5px;
    margin-bottom: 10px;
    .flex-1 {
      flex: 1;
      color: #777
    }

  }
  .count-info {
    text-align: left;
    color: #3D859D;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .user-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    left: 50%;
    margin-left: -40vw;
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
  }
  .user-titile {
    width: 100%;
    display: flex;
    height: 35px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 10px;
    font-size: 15px;
    background: #0093CE;
    border-radius: 5px;
    .headerDivider {
      box-sizing: border-box;
      border-left:1px solid #fff;
      height: 18px;
      position:absolute;
      right: 0;
      top: 8.5px;
    }
    .title-item {
      flex: 1;
      text-align: center;
      position: relative;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
  }
  .user-item {
    margin-bottom: 10px;
    .user-table {
      background: #B2B2B2;
      border-radius: 8px;
      margin-top: 10px;
      table {
        width: 100%;
        border-radius: 10px;
        border-collapse: collapse;
        font-family: "黑体";
        font-weight: 800;
        tr {
          height: 30px;
          td:first-child {
            width: 80px;
          }
          td:nth-child(1) {
            width: 80px;
          }
        }
      }
      table, th, td { border: 1px solid #D0D0D0; }
    }
  }
  .input-search {
    display: flex;
    .nickname-input {
      flex: 1;
      margin-right: 10px;
    }
  }
  .user-head {
    width: 100%;
    background: #fff;
    display: flex;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    font-family: "黑体";
    font-weight: 800;
    .title-item {
      flex: 1;
      position: relative;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
    .headerDivider {
      box-sizing: border-box;
      border-right:1px solid #86B6D4;
      height: 18px;
      position:absolute;
      right: 0;
      top: 8.5px;
    }
    .icon-right {
      height: 18px;
      position: absolute;
      right: 5px;
      top: 8.5px;
    }
    .icon-bottom {
      width: 18px;
      position: absolute;
      right: 5px;
      top: 15px;
    }
  }
}
</style>
