var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-list"},[_vm._m(0),_vm._l((_vm.list),function(user,index){return _c('div',{key:user._id,staticClass:"user-item",on:{"click":function($event){return _vm.$router.push({
    path: '/doctor/order',
    query: {
      openid: user.openid
    }
  })}}},[_c('div',{staticClass:"user-head"},[_c('div',{staticClass:"title-item"},[_vm._v(_vm._s(index + 1)),_c('div',{staticClass:"headerDivider"})]),_c('div',{staticClass:"title-item"},[_vm._v(_vm._s(user.nickname)),_c('div',{staticClass:"headerDivider"})]),_c('div',{staticClass:"title-item"},[_vm._v(_vm._s(user.sex === '0' ? '女' : '男')),_c('div',{staticClass:"headerDivider"})]),_c('div',{staticClass:"title-item"},[_vm._v(_vm._s(_vm.getDate(user.createdAt))),_c('div',{staticClass:"headerDivider"})]),_c('div',{staticClass:"title-item"},[_vm._v(" "+_vm._s(user.consume * 0.01)+" "),_c('img',{staticClass:"icon-right",attrs:{"src":require("../../assets/icon_right.png"),"alt":""}})])])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-titile"},[_c('div',{staticClass:"title-item"},[_vm._v("序号"),_c('div',{staticClass:"headerDivider"})]),_c('div',{staticClass:"title-item"},[_vm._v("姓名"),_c('div',{staticClass:"headerDivider"})]),_c('div',{staticClass:"title-item"},[_vm._v("性别"),_c('div',{staticClass:"headerDivider"})]),_c('div',{staticClass:"title-item"},[_vm._v("关注时间"),_c('div',{staticClass:"headerDivider"})]),_c('div',{staticClass:"title-item"},[_vm._v("消费")])])
}]

export { render, staticRenderFns }