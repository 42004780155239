<template>
  <div class="visit-list" >
    <div style="height: 40px">
      <mt-button class="record-btn" style="float: left" @click="$router.push(`/hospital_doctor`)" type="primary">返回</mt-button>
    </div>
    <div class='user-info'>
      <p><img :src="user.headimgurl" alt="">
      <span>{{user.wechatname}}</span>
      <span v-if="user.locked" class="locked">已锁定</span>
      <mt-button class="record-btn" @click="$router.push(`/hospital_doctor/user/record/${user.openid}`)" type="primary">使用频率</mt-button>
      </p>
      <p>姓名：{{user.nickname}}</p>
      <p>电话：{{user.mobile}}</p>
      <p>出生日期：{{getBirthDate(user.idCode)}}</p>
      <p>开始日期：{{getDate(user.startDate)}}</p>
      <p>结束日期：{{getDate(user.endDate)}}</p>
    </div>
    <div class="visit-titile">
      <div style="width: 65px;margin-right: 10px;">序号</div>
      <div style="flex: 1">时间</div>
    </div>
    <div @click="activeIndex = index" class="visit-item" v-for="(visit, index) in list" :key="visit._id">
      <div class="visit-head">
        <div style="width: 65px">{{index + 1}}<div class="headerDivider"></div></div>
        <div style="flex: 1">
          <span>{{getDate(visit.recordedAt)}}</span>
          <img v-if="activeIndex === index" class="icon-bottom" src="../../../assets/icon_bottom.png" alt="">
          <img v-else class="icon-right" src="../../../assets/icon_right.png" alt="">
        </div>
      </div>
      <div class="visit-table" v-if="activeIndex === index">
        <table border="1">
          <tbody>
            <tr>
                <td rowspan="2">眼轴</td>
                <td>左眼</td>
                <td style="flex: 1">{{visit.axis_os || '无'}}</td>
            </tr>
            <tr>
                <td>右眼</td>
                <td>{{visit.axis_od || '无'}}</td>
            </tr>
            <tr>
                <td rowspan="2">屈光度</td>
                <td>左眼</td>
                <td>{{visit.diopter_os_sph || '无'}}/{{visit.diopter_os_cyl || '无'}}/{{visit.diopter_os_axis || '无'}}</td>
            </tr>
            <tr>
                <td>右眼</td>
                <td>{{visit.diopter_od_sph || '无'}}/{{visit.diopter_od_cyl || '无'}}/{{visit.diopter_od_axis || '无'}}</td>
            </tr>
            <tr>
                <td rowspan="2">矫正视力</td>
                <td>左眼</td>
                <td>{{visit.vision_os || '无'}}</td>
            </tr>
            <tr>
                <td>右眼</td>
                <td>{{visit.vision_od || '无'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <mt-button class="visit-btn" @click="$router.push(`/hospital_doctor/user/chart/${user.openid}`)" type="primary">视力数据曲线图</mt-button>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'VisitList',
  data() {
    return {
      activeIndex: null,
      list: [],
      user: {}
    }
  },
  components: {
  },
  mounted() {
    this.getUsersByOpenId()
  },
  methods: {
    getDate (createdAt) {
      if (!createdAt) {
        return
      }
      return moment(createdAt).format('YYYY-MM-DD')
    },
    getBirthDate (IDCard) {
      if (!IDCard) {
        return ''
      }
      var year = IDCard.substring(6, 10)
      var month = IDCard.substring(10, 12)
      var day = IDCard.substring(12, 14)
      var birthDate = year + '年' + month + '月' + day + '日'
      return birthDate
    },
    getUsersByOpenId () {
      console.log(this.$route)
      this.$store.httpDispatch({})('addLog', {
        action: '查看',
        type: '用户信息',
        from: 'client',
        openid: this.$route.params.id
      }).catch((err) => {
        console.log(err)
      })
      this.$store.httpDispatch({})('getUserByDoctorId', {
        openid: this.$route.params.id
      })
      .then((res) => {
        if (res.data.code === 0) {
          this.user = res.data.data.retUser
          this.getVisits()
        }
      })
    },
    getVisits () {
      axios.get('/api/admin/visits/all', {
        params: {
          openid: this.user.openid
        }
      }).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.list = res.data.data.visits
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less">
.visit-list {
  padding: 20px 20px 0;
  padding-bottom: 70px;
  box-sizing: border-box;
  .user-info {
    img {
      width: 45px;
      height: 45px;
    }
    span {
      line-height: 45px;
      vertical-align: top;
      padding-left: 10px;
    }
    p {
      text-align: left;font-size: 15px;
    }
    .record-btn {
      position: absolute;
      right: 20px;
    }
    .locked {
      position: absolute;
      width: auto;
      color: red;
      border: 1px solid red;
      display: inline-block;
      padding: 0 10px;
      line-height: 40px;
      margin-left: 20px;
      text-align: center;
      position: absolute;
      right: 125px;
    }
  }
  .visit-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    left: 50%;
    margin-left: -40vw;
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
  }
  .visit-titile {
    background: none;
    width: 100%;
    display: flex;
    height: 35px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 10px;
    font-size: 15px;
    div {
      background: #007EC5;
      border-radius: 5px;
    }
  }
  .visit-item {
    margin-bottom: 10px;
    .visit-table {
      background: #B2B2B2;
      border-radius: 8px;
      margin-top: 10px;
      table {
        width: 100%;
        border-radius: 10px;
        border-collapse: collapse;
        font-family: "黑体";
        font-weight: 800;
        tr {
          height: 30px;
          td:first-child {
            width: 80px;
          }
          td:nth-child(1) {
            width: 80px;
          }
        }
      }
      table, th, td { border: 1px solid #D0D0D0; }
    }
  }
  .visit-head {
    position: relative;
    width: 100%;
    background: #fff;
    display: flex;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    font-family: "黑体";
    font-weight: 800;
    .headerDivider {
      box-sizing: border-box;
      border-left:1px solid #ADD6E9;
      border-right:1px solid #ADD6E9;
      height: 18px;
      position:absolute;
      left: 60px;
      top: 8.5px;
    }
    .icon-right {
      height: 18px;
      position: absolute;
      right: 20px;
      top: 8.5px;
    }
    .icon-bottom {
      width: 18px;
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}
</style>
