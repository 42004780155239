<template>
  <div class="user-record" >
    <Calendar
      v-on:choseDay="clickDay"
      v-on:changeMonth="changeMonth"
      :markDateMore="markDateMore"
      :futureDayHide="futureDayHide"
    ></Calendar>
    <div class="user-titile">
      <div class="title-item">序号<div class="headerDivider"></div></div>
      <div class="title-item">日期<div class="headerDivider"></div></div>
      <div class="title-item">使用时间</div>
    </div>
    <div class="user-item"  v-for="(record, index) in recordDataMap[activeDay]" :key="record">
      <div :class="index%2 !== 0 ? 'odd user-head' : 'user-head'">
        <div class="title-item">{{ index + 1 }}<div class="headerDivider"></div></div>
        <div class="title-item">{{ getDate(record) }}<div class="headerDivider"></div></div>
        <div class="title-item">{{ getTime(record) }}</div>
      </div>
    </div>
    <div class="records-info">
      <ul>
        <p style="color: red; font-weight: 800">配合度：{{ this.usePer }}%</p>
        <li style="color: #333;"><span>点击具体日期查看使用次数</span></li>
        <li style="color: red;"><span>红点——未使用</span></li>
        <li style="color: #50F87A"><span>绿点——使用两次</span></li>
        <li style="color: #FFA52D" ><span>黄点——使用一次</span></li>
      </ul>
      <p style="color: red">当天的使用记录需要隔天才能显示</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Calendar from 'vue-calendar-component';
import moment from 'moment'
export default {
  name: 'UsersList',
  data() {
    return {
      markDateMore: [],
      user: {},
      recordDataMap: {},
      activeDay: '',
      futureDayHide: '',
      usePer: 0,
      loading: true
    }
  },
  components: {
    Calendar
  },
  mounted() {
    this.getUsersByOpenId()
  },
  methods: {
    cureData () {
      this.getToday()
      this.user.cureRecords && this.user.cureRecords.map((record) => {
        const date = this.getDate(record)
        if (!this.recordDataMap[date]) {
          this.recordDataMap[date] = []
        }
        const activatedAt = this.getDate(this.user.startDate)
        if (moment(activatedAt) <= moment(date)) {
          this.recordDataMap[date].push(record)
        } else {
          this.recordDataMap[date] = []
        }
      })
      for (let dateKey in this.recordDataMap) {
        if (this.recordDataMap[dateKey].length > 1) {
          this.markDateMore.push({
            date: dateKey,
            className: 'mark1'
          })
        }
        if (this.recordDataMap[dateKey].length === 1) {
          this.markDateMore.push({
            date: dateKey,
            className: 'mark2'
          })
        }
      }
      this.changeMonth()

    },
    getUsersByOpenId () {
      this.$store.httpDispatch({})('getUserByDoctorId', {
        openid: this.$route.params.id
      })
      .then((res) => {
        if (res.data.code === 0) {
          this.user = res.data.data.retUser
          this.cureData()
        }
      })
    },
    getDate(time) {
      if (!time) {
        return ''
      }
      return moment(time).format('YYYY-MM-DD')
    },
    getTime(time) {
      if (!time) {
        return ''
      }
      return moment(time).format('HH:mm')
    },
    getToday () {
      let futureDayHide = moment().startOf('day').toDate().getTime() / 1000
      this.futureDayHide = futureDayHide.toString()
    },
    clickDay(data) {
      this.activeDay = this.getDate(data)
    },
    changeDate(data) {
    },
    changeMonth(data) {
      const activeMonth = data ? new moment(data) : new moment()
      this.usePer = 0
      if (!this.user.cureRecords) return
      const useRecords = []
      this.user.cureRecords.map((record) => {
        const activatedAt = this.getDate(this.user.startDate)
        const date = this.getDate(record)
        if (moment(activatedAt) <= moment(date) && moment(date).format('YYYY-MM') === activeMonth.format('YYYY-MM')) {
          useRecords.push(date)
        }
      })
      if (useRecords.length > 0) {
        let startDay = data ? new moment(data) : new moment()
        let endDay = data ? new moment(data) : new moment()
        startDay = startDay.startOf('month')
        endDay = endDay.endOf('month')
        if (moment() < endDay) {
          endDay = moment().endOf('day').subtract(1, 'day')
        }
        if (moment(this.user.startDate) > startDay) {
          startDay = moment(this.user.startDate).startOf('day')
        }
        let useDays = endDay.diff(startDay, 'day') + 1
        this.usePer = (useRecords.length / (useDays * 2)) * 100
        this.usePer = this.usePer.toFixed(2)
        this.usePer = this.usePer > 100 ? 100 : this.usePer
      }
    },
    clickToday(data) {
    }
  }
}
</script>

<style lang="less">
.user-record .wh_content_all {
  background: #fff!important;
  .wh_isMark {
    background: none;
  }
  .wh_top_changge li {
    color: #333;
  }
  .wh_top_changge .wh_jiantou1 {
    border-top: 2px solid #333;
    border-left: 2px solid #333;
  }
  .wh_top_changge .wh_jiantou2 {
    border-top: 2px solid #333;
    border-right: 2px solid #333;
  }
  .wh_content_item, .wh_content_item_tag {
    color: #333;
  }
  .wh_item_date:hover {
    background: none!important;
  }
  .wh_item_date:after {
    content: "";
    width: 5px;
    height: 5px;
    background: red;
    border-radius: 50%;
    position:relative;
    top: -10px;
  }
  .wh_want_dayhide:after {
    background: none;
  }
  .mark2:after {
    content: "";
    width: 5px;
    height: 5px;
    background: #FFA52D;
    border-radius: 50%;
    position:relative;
    top: -10px;
  }
  .mark1:after {
    content: "";
    width: 5px;
    height: 5px;
    background: #50F87A;
    border-radius: 50%;
    position:relative;
    top: -10px;
  }
  .wh_isToday {
    background: #3C9975!important;
    color: #fff;
  }
  .wh_chose_day {
    background: #fff!important;
    color: #333;
    border: 2px solid #333;
    box-sizing: bord-box;
  }
}

.user-record {
  box-sizing: border-box;
  .records-info {
    text-align: left;
    margin-top: 30px;
    ul {
      li {
          color: red;
          span {
            color: #333;
          }
      }
    }
  }
  .user-titile {
    width: 100%;
    display: flex;
    height: 35px;
    line-height: 35px;
    color: #fff;
    font-size: 15px;
    background: #0093CE;
    .headerDivider {
      box-sizing: border-box;
      border-left:1px solid #fff;
      height: 18px;
      position:absolute;
      right: 0;
      top: 8.5px;
    }
    .title-item {
      flex: 1;
      text-align: center;
      position: relative;
    }
  }
  .user-item {
    border-bottom: 1px solid #ddd;
  }
  .odd {
    background: #eee!important;
  }
  .user-head {
    width: 100%;
    background: #fff;
    display: flex;
    height: 35px;
    line-height: 35px;
    font-family: "黑体";
    font-weight: 800;
    .title-item {
      flex: 1;
      position: relative;
    }
    .headerDivider {
      box-sizing: border-box;
      border-right:1px solid #86B6D4;
      height: 18px;
      position:absolute;
      right: 0;
      top: 8.5px;
    }
  }
}
</style>
