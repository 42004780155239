<template>
  <div class="doctor-home-page">
    <div>
      <h1>红光近视控制项目</h1>
      <p>客服——{{user.nickname}}</p>
      <img class="qrcode doctor-code" :src ="`data:image/png;base64,${qrCode.toString('base64')}`" />
      <p>二维码生成一次后永久生效</p>
      <p>修改资料不影响二维码的生成</p>
    </div>
    <div>
      <mt-button style="margin-right: 20px" class="user-btn" @click="$router.push('/doctor/info')" type="primary">资料修改</mt-button>
      <mt-button class="user-btn"  @click="$router.push('/doctor/user')" type="primary">相关患者</mt-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Toast } from 'mint-ui'
import { mapState } from 'vuex'
import qr from 'qr-image'

export default {
  name: 'home-page',
  data() {
    return {
      mobile: '',
      smsCode: '',
      qrCode: ''
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
    console.log(this.$route)
    this.getQrCode(this.user.qrCodeUrl)
  },
  updated: function () {
    if (!this.qrCode) {
      this.getQrCode(this.user.qrCodeUrl)
    }
  },
  methods: {
    getQrCode (url) {
      if (!url) return ''
      let qrCode = qr.imageSync(url, 'L')
      qrCode = qrCode.toString('base64')
      this.qrCode = qrCode
      return qrCode
    }
  }
}
</script>
<style lang="less" scoped>
.doctor-home-page {
  padding: 20px 20px 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  .user-cell {
    text-align: left;
    padding: 20px 20px;
    border-radius: 10px;
  }
  .user-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 100px;
  }
}
</style>
