<template>
  <div class="edit-visit-page">
    <div>
      <mt-popup
        class="image-popup"
        v-model="popupVisible">
        <img
          :src="src">
      </mt-popup>
      <div style="text-align: left"
          @click="popupVisible = true">
        <div class="report-image" v-for="key of keys" :key="key">
          <div
            style="display: inline-block"
            v-for="image in images_list[key]"
            :key="image.url">
            <img
              style="width: 50px;height: 50px;margin: 0px 5px"
              @click="showImage(image.url)"
               :src="image.url">
          </div>
          <p>{{ imageTypeMap[key] }}</p>
        </div>
      </div>
      <div>
        <mt-field disabled :readonly="true" label="名称" v-model="user.nickname" ></mt-field>
        <mt-field disabled :readonly="true" label="性别" v-model="user.gender" ></mt-field>
        <mt-field disabled :readonly="true" label="出生日期" v-model="user.birthday" ></mt-field>
        <mt-field disabled :readonly="true" label="居住地" v-model="user.provinceCity" ></mt-field>
      </div>
      <div>
        <mt-field label="左眼眼轴" v-model="ruleForm.axis_os" ></mt-field>
        <mt-field label="右眼眼轴" v-model="ruleForm.axis_od" ></mt-field>
        <mt-field label="左眼屈光度球镜" v-model="ruleForm.diopter_os_sph" ></mt-field>
        <mt-field label="左眼屈光度柱镜" v-model="ruleForm.diopter_os_cyl" ></mt-field>
        <mt-field label="左眼屈光度轴位" v-model="ruleForm.diopter_os_axis" ></mt-field>
        <mt-field label="右眼屈光度球镜" v-model="ruleForm.diopter_od_sph" ></mt-field>
        <mt-field label="右眼屈光度柱镜" v-model="ruleForm.diopter_od_cyl" ></mt-field>
        <mt-field label="右眼屈光度轴位" v-model="ruleForm.diopter_od_axis" ></mt-field>
        <mt-field label="左眼视力" v-model="ruleForm.vision_os" ></mt-field>
        <mt-field label="右眼视力" v-model="ruleForm.vision_od" ></mt-field>
      </div>
      <mt-button :disabled="loading" class="user-btn" @click="submitForm" type="primary">提交</mt-button>
    </div>
  </div>
</template>
<style lang="less">
  .edit-visit-page {
    background: #fff;
    margin-top: 10px;
    width: 100%;
    min-height: 100vh;
    text-align: left;
    img {
      width: 100px;
      height: 100px;
    }
    .mint-field .mint-cell-title {
      width: 120px
    }
    .report-image {
      padding: 0 20px;
    }
    .image-popup {
      width: 85vw;
      height: 80vh;
      overflow: scroll;
      img {
        width: auto;
        height: 80vh;
      }
    }
    .user-btn {
      height: 35px;
      line-height: 35px;
      background: #007EC5;
      width: 80vw;
      border-radius: 100px;
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 10vw;
    }
    .info-items {
      font-size: 15px;
      line-height: 40px;
    }
  }
</style>

<script>
import moment from 'moment'
import { Toast } from 'mint-ui'
export default {
  data () {
    return {
      user: {},
      visit: {},
      src: '',
      popupVisible: false,
      loading: false,
      ruleForm: {
        diopter_od_sph: '',
        diopter_od_cyl: '',
        diopter_od_axis: '',
        diopter_os_sph: '',
        diopter_os_cyl: '',
        diopter_os_axis: '',
        axis_od: '',
        axis_os: '',
        vision_os: '',
        vision_od: ''
      },
      keys: ['image_vision', 'image_mydriatic', 'image_biometric'],
      images_list: {
        image_vision: [],
        image_mydriatic: [],
        image_biometric: []
      },
      imageTypeMap: {
        image_vision: '含视力结果的病历',
        image_mydriatic: '散瞳验光结果',
        image_biometric: '生物测量结果'
      },
      rules: {
        axis_od: [
          { required: true, message: '右眼眼轴不得为空', trigger: 'blur' }
        ],
        axis_os: [
          { required: true, message: '左眼眼轴不得为空', trigger: 'blur' }
        ],
        vision_od: [
          { required: true, message: '右眼视力不得为空', trigger: 'blur' }
        ],
        vision_os: [
          { required: true, message: '左眼视力不得为空', trigger: 'blur' }
        ]
      }
    }
  },
  mounted: async function () {
    this.getUser()
    this.getVisit()
  },
  methods: {
    getDate (createdAt) {
      return moment(createdAt).format('YYYY年MM月DD日 HH:mm')
    },
    showImage (src) {
      this.src = src
      this.popupVisible = true
    },
    submitForm () {
      const body = this.ruleForm
      for (let key in body) {
        if (this.rules[key] && this.rules[key][0].required && !body[key]) {
          return Toast({
            message: this.rules[key][0].message,
            duration: 3000
          })
        }
      }
      body.id = this.$route.query.id
      const that = this
      this.loading = true
      this.$store.httpDispatch({})('updateVisit', body).then((res) => {
        const { code, data } = res.data
        this.loading = false
        if (code === 0) {
          return Toast({
            message: '保存成功',
            duration: 3000
          })
        } else {
          return Toast({
            message: '请求失败',
            duration: 3000
          })
        }
      }).catch((error) => {
        this.loading = false
        return Toast({
          message: '请求失败',
          duration: 3000
        })
      })
    },
    async getUser () {
      let result = {}
      try {
        result = await this.$store.dispatch('getUser', {
          openid: this.$route.query.openid
        })
      } catch (err) {
        console.log(err)
        return Toast({
          message: '请求失败',
          duration: 3000
        })
        return false
      }
      const { code, data } = result.data
      if (code === 0) {
        console.log(data)
        data.user.provinceCity = `${data.user.province}-${data.user.city}`
        data.user.gender = data.user.sex !== 1 ? '女' : '男'
        this.user = data.user
      }
    },
    async getVisit () {
      let result = {}
      try {
        result = await this.$store.dispatch('getVisit', {
          id: this.$route.query.id
        })
      } catch (err) {
        console.log(err)
        return Toast({
          message: '请求失败',
          duration: 3000
        })
        return false
      }
      const { code, data } = result.data
      if (code === 0) {
        console.log(data)
        this.visit = data.visit
        this.ruleForm.diopter_od_sph = this.visit.diopter_od_sph
        this.ruleForm.diopter_od_cyl = this.visit.diopter_od_cyl
        this.ruleForm.diopter_od_axis = this.visit.diopter_od_axis
        this.ruleForm.diopter_os_sph = this.visit.diopter_os_sph
        this.ruleForm.diopter_os_cyl = this.visit.diopter_os_cyl
        this.ruleForm.diopter_os_axis = this.visit.diopter_os_axis
        this.ruleForm.axis_od = this.visit.axis_od
        this.ruleForm.axis_os = this.visit.axis_os
        this.visit.images.forEach(image => {
          this.images_list[image.type].push(image)
        });
      }
    }
  }
}
</script>
