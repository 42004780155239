<template>
  <div class="user-list" >
    <div class="user-titile">
      <div class="title-item">产品名称<div class="headerDivider"></div></div>
      <div class="title-item">费用</div>
    </div>
    <div v-for="(order) in orders" :key="order._id">
        <div class="user-item" v-for="(product) in order.products" :key="product.product_id">
          <div class="user-head">
            <div class="title-item">{{ product.product_name }}<div class="headerDivider"></div></div>
            <div class="title-item">{{ product.product_price * product.product_count * 0.01 }}</div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
export default {
  name: 'UsersList',
  data() {
    return {
      orders: [],
      list: []
    }
  },
  components: {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted() {
    this.getUsersByOpenId()
  },
  methods: {
    getUsersByOpenId () {
      this.$store.httpDispatch({})('getUsersByOpenId', {
        openid: this.$route.query.openid
      })
      .then((res) => {
        if (res.data.code === 0) {
          this.list = res.data.data.users
          this.orders = this.list[0] ? this.list[0].orders : []
        }
      })
    }
  }
}
</script>

<style lang="less">
.user-list {
  padding-top: 50px;
  padding: 50px 20px 0;
  box-sizing: border-box;
  .user-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    left: 50%;
    margin-left: -40vw;
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
  }
  .user-titile {
    width: 100%;
    display: flex;
    height: 35px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 10px;
    font-size: 15px;
    background: #0093CE;
    border-radius: 5px;
    .headerDivider {
      box-sizing: border-box;
      border-left:1px solid #fff;
      height: 18px;
      position:absolute;
      right: 0;
      top: 8.5px;
    }
    .title-item {
      flex: 1;
      text-align: center;
      position: relative;
    }
  }
  .user-item {
    margin-bottom: 10px;
    .user-table {
      background: #B2B2B2;
      border-radius: 8px;
      margin-top: 10px;
      table {
        width: 100%;
        border-radius: 10px;
        border-collapse: collapse;
        font-family: "黑体";
        font-weight: 800;
        tr {
          height: 30px;
          td:first-child {
            width: 80px;
          }
          td:nth-child(1) {
            width: 80px;
          }
        }
      }
      table, th, td { border: 1px solid #D0D0D0; }
    }
  }
  .user-head {
    width: 100%;
    background: #fff;
    display: flex;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    font-family: "黑体";
    font-weight: 800;
    .title-item {
      flex: 1;
    position: relative;
    }
    .headerDivider {
      box-sizing: border-box;
      border-right:1px solid #86B6D4;
      height: 18px;
      position:absolute;
      right: 0;
      top: 8.5px;
    }
    .icon-right {
      height: 18px;
      position: absolute;
      right: 5px;
      top: 8.5px;
    }
    .icon-bottom {
      width: 18px;
      position: absolute;
      right: 5px;
      top: 15px;
    }
  }
}
</style>
